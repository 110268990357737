<template>
  <div v-if="variable">
    <title-bar
      submitBtn
      :submitBtnTitle="$t('variable.update')"
      :title="$t('variable.update')"
      @submitPressed="submitPressed"
    />
    <variables-form
      :variableObject="variable"
      :action="action"
      @clearAction="action = null"
    />

    <b-card>
      <custom-fields ownerResource="CUSTOM_RESOURCE" :ownerId="variable.id" :existingCustomFields="variable.customFields" />
    </b-card>
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import VariablesForm from "./components/VariablesForm.vue";
import CustomFields from "@/components/CustomFields/customFields.vue";

export default {
  components: {
    TitleBar,
    VariablesForm,
    CustomFields
  },
  data() {
    return {
      variable: null,
      action: null,
    };
  },
  created() {
    this.loadVariable();
  },
  methods: {
    loadVariable() {
      const id = this.$route.params.id;
      this.$Variables.getResource({ id }).then((response) => {
        this.variable = response.data;
      })
    },
    submitPressed() {
      this.action = "update";
    },
  },
};
</script>
